export const WEDDING_INVITATION_URL = "http://invi.ybchoi.com/";
export const KAKAOTALK_API_TOKEN = "511ea794e9db1704991a4aedeed8d5a7";
export const KAKAOTALK_SHARE_IMAGE =
  "https://cdn.pixabay.com/photo/2014/11/13/17/04/heart-529607_960_720.jpg";

export const WEDDING_DATE = "2024년 12월 21일, 토요일 12시";
export const WEDDING_LOCATION = "코트야드 메리어트 서울 타임스퀘어, 4층 주니어볼룸";

export const GROOM_NAME = "최용범";
export const GROOM_ACCOUNT_NUMBER = "국민은행 010-2048-9009";
export const GROOM_FATHER_NAME = "최판만";
export const GROOM_FATHER_ACCOUNT_NUMBER = "○○은행 ***-***-******";
export const GROOM_MOTHER_NAME = "장순애";
export const GROOM_MOTHER_ACCOUNT_NUMBER = "○○은행 ***-***-******";

export const BRIDE_NAME = "소다미";
export const BRIDE_ACCOUNT_NUMBER = "우리은행 010-5031-1422";
export const BRIDE_FATHER_NAME = "소인섭";
export const BRIDE_FATHER_ACCOUNT_NUMBER = "○○은행 ***-***-******";
export const BRIDE_MOTHER_NAME = "김미숙";
export const BRIDE_MOTHER_ACCOUNT_NUMBER = "○○은행 ***-***-******";